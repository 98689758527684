/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable max-len */
import { BaseSignalRModelRequestJSON } from '@/signalRRequest/SignalRModelRequest';

export class CustomCategoryFolder {
  public static createEmpty(): CustomCategoryFolder {
    return new CustomCategoryFolder();
  }

  public static GetFolderItemFromFolderItemSignalR(fItem: CustomCategoryFolder, folder: CustomCategoryFolderItemSignalR): CustomCategoryFolder {
    fItem.Id = folder.Id;
    fItem.Name = folder.Name;
    fItem.IconUrl = folder.IconUrl;
    fItem.IconUrlOriginal = folder.IconUrlOriginal;
    fItem.PinnedToMenu = folder.PinnedToMenu;
    fItem.FontAwesomeIconId = folder.FontAwesomeIconId;
    fItem.FontAwesomeIconCode = folder.FontAwesomeIconCode;
    fItem.FontAwesomeIconName = folder.FontAwesomeIconName;
    return fItem;
  }
  constructor(
    public Id: number= 0,
    public Name: string= '',
    public IconUrl: string|null  = '',
    public IconUrlOriginal: string|null = '',
    public PinnedToMenu: boolean= false,
    public FontAwesomeIconId: number = 0,
    public FontAwesomeIconCode: string = '',
    public FontAwesomeIconName: string = '',
    // to retain values for cancel operation
    public OldIconUrl: string|null  = '',
    public OldIconUrlOriginal: string|null  = '',
    public OldFontAwesomeIconId: number = 0,
    public OldFontAwesomeIconName: string = '',
    public OldName: string= '',
    public IsSelected: boolean= false,
  ) {}
}
export class CustomCategoryFolderItemSignalR extends BaseSignalRModelRequestJSON {
  public static createEmpty(): CustomCategoryFolderItemSignalR {
    return new CustomCategoryFolderItemSignalR();
  }

  public static GetCustomCategoryFoldersItemSignalR(folder: CustomCategoryFolder): CustomCategoryFolderItemSignalR {
    const item: CustomCategoryFolderItemSignalR = CustomCategoryFolderItemSignalR.createEmpty();
    item.Id = folder.Id;
    item.Name = folder.Name;
    item.IconUrl = folder.IconUrl!;
    item.IconUrlOriginal = folder.IconUrlOriginal!;
    item.PinnedToMenu = folder.PinnedToMenu;
    item.FontAwesomeIconCode = folder.FontAwesomeIconCode;
    item.FontAwesomeIconId = folder.FontAwesomeIconId;
    item.FontAwesomeIconName = folder.FontAwesomeIconName;
    item.selectedName = folder.Name;
    item.selectedCount = 1;
    return item;
  }

  constructor(
    public Id: number = 0,
    public Name: string = '',
    public IconUrl: string = '',
    public IconUrlOriginal: string = '',
    public PinnedToMenu: boolean= false,
    public FontAwesomeIconId: number = 0,
    public FontAwesomeIconCode: string = '',
    public FontAwesomeIconName: string = '',
  ) {
    super(0, '');
  }
}
