
























































































import { Component, Prop, Vue, Watch} from 'vue-property-decorator';
import PrefixMediaUpload from '@/entities/MediaPrefix';
@Component

export default class InlineFileUploader extends Vue {
  @Prop()
  private allowedExtensions?: string;
  @Prop()
  private fileMaxSize?: number;
  @Prop()
  private allowMultipleFiles?: boolean;
  @Prop()
  private headingTitle?: string;
  @Prop()
  private openFileText?: string;
  @Prop()
  private existingFileUrl?: string;
  @Prop() private ModuleName?: string;
  @Prop() private SubModuleName?: string;
  // Local variables
  private localAllowedExtensions?: string = '';
  private localMaxSize?: number = 1;
  private localAllowMultipleFiles?: boolean = false;
  private localBlobUrl = '';
  private localFileIsValid = false;
  private iconfilename: string = this.$t('VirtualMeetings.AddVideo.ChooseFile').toString();
  private errorText = '';
  private showReplaceSection = false;
  private maxSize = 1; // 1 MB
  private uploadFile: string = this.$t('CustomCategory.File').toString();
  private fileIconUrl: string = require('@/assets/Images/pdf_document.svg');
  private fileUrl = '';
  private localHeading = '';
  private localOpenFileText?: string = '';
  private uploadUrl = '/FileUpload/UploadMediaDuringEditJson';
  private loadingImage: string = require('@/assets/Images/loading.png');
  private ShowLoader = false;
  private mediaPrefix = '';
  // Fill value in local variable
  private mounted() {
    this.localAllowedExtensions = this.allowedExtensions!;
    this.localMaxSize = this.fileMaxSize!;
    this.localAllowMultipleFiles = this.allowMultipleFiles!;
    this.localHeading = this.headingTitle!;
    this.localOpenFileText = this.openFileText!;
    this.fileUrl = this.existingFileUrl!;
    if (this.fileUrl !== '' && this.fileUrl !== undefined) {
      this.localHeading = this.$t('CustomCategory.FileUpload').toString();
      this.showReplaceSection = !this.showReplaceSection;
      // Emit the URL and IsValid value to child component
      this.$emit('is-valid-file', true);
    }
  }
  private GetMediaPrefix() {
    if(this.ModuleName !== null && this.ModuleName !== undefined && this.SubModuleName !== null && this.SubModuleName !== undefined) {
      this.mediaPrefix = new PrefixMediaUpload().GetPrefix(this.ModuleName,this.SubModuleName);
    }
  }
  // On file change validate the file and if success the emit the URL and valiate status
  private onFileChange(fieldName: string, file: any, isReplace: boolean) {
    this.ShowLoader = true;
    const uploadedFile = file[0];
    if (file.length > 0) {
      this.errorText = '';
      if (!uploadedFile.name.includes(this.localAllowedExtensions)) {
        // emit error and text
        this.errorText = this.$t('FileUpload.Upload.ErrorChoosePdfOnly').toString()!;
        this.$emit('is-valid-file', false);
        this.$emit('error-message', this.errorText);
        this.ShowLoader = false;
      } else {
        // In case of success
        // Get PDF Full URL
        this.UploadFile(
          this.uploadUrl,
          uploadedFile,
          isReplace,
        );
      }
    }
  }
  private UploadFile(
    url: string,
    file: any,
    isReplace: boolean,
  ) {
    const formData = new FormData();
    this.GetMediaPrefix();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    formData.append('file', file);
    formData.append('thumbnailWidth', '0');
    formData.append('thumbnailHeight', '0');
    formData.append('mediaPrefix',this.mediaPrefix);
    const options = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    this.$axios
      .post(url, formData, options)
      .then((response) => {
        if (!isReplace) {
          this.localHeading = this.$t('CustomCategory.FileUpload').toString();
          this.showReplaceSection = !this.showReplaceSection;
        }
        // Emit the URL and IsValid value to child component
        this.fileUrl = response.data.fullUri;
        this.$emit('is-valid-file', true);
        this.$emit('fileurl', this.fileUrl);
        this.$emit('error-message', '');
        this.ShowLoader = false;
      })
      .catch(() => {
        // Log error if required
        this.ShowLoader = false;
      });
  }
  // watch on existing File Url and update the local variable
  @Watch('existingFileUrl')
  private onExistingFileUrl(
    value: string,
  ) {
    this.fileUrl = value!;
    if (this.fileUrl !== '' && this.fileUrl !== undefined) {
      this.localHeading = this.$t('CustomCategory.FileUpload').toString();
      this.showReplaceSection = true;
      // Emit the URL and IsValid value to child component
      this.$emit('is-valid-file', true);
      this.$emit('error-message', '');
      this.errorText = '';
    }
  }
}
