import { Create } from './CustomCategoryFactoryCreator';
import { BaseCustomCategoryRenderer} from './BaseCustomCategoryRenderer';
import CustomCategoryTypeEnum from '@/enums/CustomCategoryTypeEnum';
import i18n from '@/i18n'; // its used for translation. i18n.t() same as $t

export class SponsorRenderer implements Create {
  public GetValues(typeId: number): BaseCustomCategoryRenderer {
    const SponsorRendererBaseClass: BaseCustomCategoryRenderer = new BaseCustomCategoryRenderer();
    SponsorRendererBaseClass.CustomCategoryType = CustomCategoryTypeEnum[typeId].toString();
    SponsorRendererBaseClass.CustomCategoryTypeId = typeId;
    SponsorRendererBaseClass.ShowImage = true;
    SponsorRendererBaseClass.ShowDescription = true;
    SponsorRendererBaseClass.ShowLinkedParticipantProfile = true;
    SponsorRendererBaseClass.ShowLinkedProgramItem = true;
    SponsorRendererBaseClass.ImageUploadText = i18n.t('AddImage').toString();
    SponsorRendererBaseClass.DescriptionText = i18n.t('CustomCategory.AboutSponsor').toString();
    SponsorRendererBaseClass.LinkParticipantProfileText = i18n.t('CustomCategory.LinkSponsorParticipantText').toString();
    SponsorRendererBaseClass.LinkMultipleParticipantProfile = true;
    SponsorRendererBaseClass.TitleText = i18n.t('Name').toString();
    SponsorRendererBaseClass.CustomCategoryHeading =  i18n.t('CustomCategory.Sponsor').toString()+' '+ i18n.t('Information.Information').toString();
    SponsorRendererBaseClass.LinkParticipantProfileNoProfileText = i18n.t('CustomCategory.LinkParticipantProfileNoProfileText', {type: SponsorRendererBaseClass.CustomCategoryType}).toString();
    SponsorRendererBaseClass.ShowVOD = true;
    SponsorRendererBaseClass.ShowURL = true;
    SponsorRendererBaseClass.UrlText = i18n.t('CustomCategory.SponsorUrlText').toString();
    SponsorRendererBaseClass.ShowMultimedia = true;
    return SponsorRendererBaseClass;
  }
}
