























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import { required, minLength } from 'vuelidate/lib/validators';
import ImageProcess from '@/components/Common/ImageProcess.vue';
import loading from '@/components/Common/loading.vue';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { FolderSignalRModel } from '@/signalRRequest/CustomCategory/FolderSignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
@Component({
  validations: {
    localFolderItem: {
      Name: {
        required,
        minLength: minLength(1),
      },
    },
  },
  methods: {
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },
  components: {
    ImageProcess,
    loading,
  },
})
export default class CustomCategoryFolderEditItemPane extends Vue {
  public showLoading = false;
  public loaderBorderColor?: string = '';
  @Prop() private selectedFolderItem?: CustomCategoryFolder;
  @Prop() private applicationborderColor?: string;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private showImageProcess = false;
  private localFolderItem: CustomCategoryFolder | null = null;

  private created() {
    if (this.selectedFolderItem != null) {
      this.localFolderItem = CustomCategoryFolder.createEmpty();
      // assign current image url to old image url property for cancel operation
      if (
        this.selectedFolderItem.OldIconUrl === '' ||
        this.selectedFolderItem.OldIconUrl == null
      ) {
        this.selectedFolderItem.OldIconUrl = this.selectedFolderItem.IconUrl;
        this.selectedFolderItem.OldIconUrlOriginal = this.selectedFolderItem.IconUrlOriginal;
        this.selectedFolderItem.OldName = this.selectedFolderItem.Name;
      }
      if (!this.selectedFolderItem.OldFontAwesomeIconId || this.selectedFolderItem.OldFontAwesomeIconId === 0) {
        this.selectedFolderItem.OldFontAwesomeIconId = this.selectedFolderItem.FontAwesomeIconId;
        this.selectedFolderItem.OldFontAwesomeIconName = this.selectedFolderItem.FontAwesomeIconName;
      }

      this.localFolderItem = Object.assign(
        this.localFolderItem,
        this.selectedFolderItem,
      );
    }
  }

  private mounted() {
    const self = this;
    this.$root.$on(
      'customCategory-folder-item-imageupdated-operation',
      (pt: CustomCategoryFolder) => {
        if (pt != null) {
          if (pt.Id === self.localFolderItem!.Id) {
            this.localFolderItem!.PinnedToMenu = pt.PinnedToMenu;
          }
        }
      },
    );
  }
  private Cancel() {
    this.$v.$reset();
    // update initial values to selected folder item for cancel operation
    this.selectedFolderItem!.IconUrl = this.selectedFolderItem!.OldIconUrl;
    this.selectedFolderItem!.IconUrlOriginal = this.selectedFolderItem!.OldIconUrlOriginal;
    this.selectedFolderItem!.Name = this.selectedFolderItem!.OldName;
    this.selectedFolderItem!.FontAwesomeIconId = this.selectedFolderItem!.OldFontAwesomeIconId;
    this.selectedFolderItem!.FontAwesomeIconName = this.selectedFolderItem!.OldFontAwesomeIconName;
    this.$emit('customCategory-folder-item-cancel-operation', this.selectedFolderItem);
  }
  private SendNotificationRequest(): SignalRModelRequest {
    setTimeout(() => {
      this.$root.$emit('show-notification-popup', true);
      this.$root.$emit('show-notification-loading', true);
    }, 100);
    const signalR: FolderSignalRModel = new FolderSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();

    if (this.localFolderItem!.Id === 0) {
      signalObject = signalR.SingleCreateFolderItemSignalRModelRequest;
    } else {
      signalObject = signalR.SingleUpdateFolderItemSignalRModelRequest;
      const arr: number[] = [];
      arr.push(this.localFolderItem!.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
    }).toString();

    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({selectedName: this.localFolderItem!.Name});
    signalR.AddToNotificationPopup(signalObject);
    return signalObject;
  }

  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    customCategoryFolderItem: CustomCategoryFolder,
  ) {
    const signalR: FolderSignalRModel = new FolderSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(customCategoryFolderItem.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = signalR.GetFolderItemSendToSignalR(customCategoryFolderItem);
    signalR.PerformFolderSingleOperation(signalObject);
  }
  private Submit() {
    this.$v.$touch();
    if (this.$v.$anyError) {
      return;
    }
    this.$v.$reset();
    // No errors submitting.
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    if (this.localFolderItem!.PinnedToMenu) {
      this.localFolderItem!.IconUrl = null;
      this.localFolderItem!.IconUrlOriginal = null;
    }
    const signalObject = this.SendNotificationRequest();
    this.$axios
      .post<CustomCategoryFolder>(
      '/CustomCategory/EditCustomFolder',
      this.localFolderItem,
    )
      .then((response) => {
        // Successful update / save
        this.$emit(
          'customCategory-folder-item-edit-operation',
          response.data,
        );
        this.$root.$emit(
          'customCategory-folder-item-edit-operation',
          response.data,
        );
        this.SendNotificationRequestToQueue(
          signalObject,
          response.data,
        );

      })
      .catch(() => {
        // Log error if required
      });
  }
  private PinToMenu() {
    this.RemoveIcon();
    if (this.localFolderItem!.PinnedToMenu) {
      this.localFolderItem!.FontAwesomeIconId = 0;
      this.localFolderItem!.FontAwesomeIconName = '';
      this.localFolderItem!.PinnedToMenu = false;
    } else {
      this.localFolderItem!.FontAwesomeIconId = 17;
      this.localFolderItem!.FontAwesomeIconName = 'fa fa-info-circle';
      this.localFolderItem!.PinnedToMenu = true;
    }
  }
  private RemoveIcon() {
    this.localFolderItem!.IconUrl = '';
    this.localFolderItem!.IconUrlOriginal = '';
  }
  private GetImageUrl() {
    return this.localFolderItem!.IconUrl === null
      ? this.missingImage
      : this.localFolderItem!.IconUrl === ''
        ? this.missingImage
        : this.localFolderItem!.IconUrl;
  }
  private clickOnImage(val: boolean) {
    this.selectedFolderItem!.Name = this.localFolderItem!.Name;
    this.showImageProcess = val;
    this.$emit('customCategory-folder-item-edit-operation', this.localFolderItem);
    if (this.localFolderItem!.PinnedToMenu) {
      this.$emit('folder-information-fonticon', val);
    } else {
      this.$emit('customCategory-folder-item-imageUpload-operation', val);
    }

  }
  private SetImageIconUrl(iconUrl: string, iconUrlOriginal: string) {
    this.localFolderItem!.IconUrl = iconUrl;
    this.localFolderItem!.IconUrlOriginal = iconUrlOriginal;
    this.$emit('customCategory-folder-item-imageUpload-completed', this.localFolderItem);
  }
  private GetPlaceholder(): string {
    return this.$t('Folder.Placeholder').toString();
  }
}
