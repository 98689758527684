/* eslint-disable max-len */
import { AxiosInstance } from 'axios';
import { BaseBulkOperationsModel } from '../BaseBulkOperationsModel';
import { CustomCategorySignalRModel } from '@/signalRRequest/CustomCategory/CustomCategorySignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
export class CustomCategoryBulkOperations extends BaseBulkOperationsModel {
  constructor(rootObj: any, axios: AxiosInstance, $store: any, $t: any) {
    super();
    const storeHelper: StoreHelper = new StoreHelper($store);
    const appInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    const isEnableFeaturedImage = !appInfo.EnableLinkProgramItemsToGroup;
    const signalR: CustomCategorySignalRModel = new CustomCategorySignalRModel(rootObj, axios, $store, $t);

    this.arrBulkOperations.push({ Id: 1, Name: 'Delete', IconUrl: 'fa fa-trash-o font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Permanently delete your selected items', ConfirmationMessage: 'Notifications.SureDeleteInformationItems', ConfirmationMessageSubTitle: '', objModel: signalR.DeleteSelectedCustomCategorySignalRModelRequest, ShowConfirmationPopup: true });

    if (isEnableFeaturedImage) {
      this.arrBulkOperations.push({ Id: 2, Name: 'Edit Icons & Images', IconUrl: 'fa fa-picture-o font-awesome-icon', IconPadding: 'padding:17px 17px 17px 0px;', Description: 'Apply an icon or a featured image to selected items', ConfirmationMessage: 'Notifications.SureUpdateInformationItemsImage', ConfirmationMessageSubTitle: '', objModel: signalR.EditSelectedCustomCategoryIconsAndImagesSignalRModelRequest, ShowConfirmationPopup: false });
    } else {
      this.arrBulkOperations.push({ Id: 2, Name: 'Edit Icons', IconUrl: 'fa fa-picture-o font-awesome-icon', IconPadding: 'padding:17px 17px 17px 0px;', Description: 'Apply an icon to selected items', ConfirmationMessage: 'Notifications.SureUpdateInformationItemsImage', ConfirmationMessageSubTitle: '', objModel: signalR.EditSelectedCustomCategoryIconsAndImagesSignalRModelRequest, ShowConfirmationPopup: false });
    }

    this.arrBulkOperations.push({ Id: 3, Name: 'Assign to Folders', IconUrl: 'fa fa-sitemap font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Add or edit your selected items into folders', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkAssignFolderSignalRModelRequest, ShowConfirmationPopup: false });

    this.arrBulkOperations.push({ Id: 4, Name: 'Link Programs', IconUrl: 'fa fa-link font-awesome-icon', IconPadding: 'padding:17px 17px 17px 0px;', Description: 'Link program items to your selected items', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkLinkProgramSignalRModelRequest, ShowConfirmationPopup: false });
  }
}
