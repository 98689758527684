






































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@/entities/FontAwesomeViewModel';
import { CustomCategoryFolder } from '../../entities/CustomCategory/CustomCategoryFolder';

@Component({})
export default class InformationFontIcon extends Vue {
  @Prop() private HeadingTitle?: string;
  @Prop() private fontAwesomeIcons?: FontAwesomeIcon[];
  @Prop() private customCategoryFolder?: CustomCategoryFolder;
  private isMobileView = false;
  private selectedFontAwesomeIcon: FontAwesomeIcon = FontAwesomeIcon.createEmpty();
  private GetFontAwesomeIconIconNameClass() {
    return {
      'font-size': 'xx-large',
      'cursor': 'pointer',
      'font-weight': 'bold',
      'margin': '5px 10px',
    };
  }

  private ClickIconGenerator(fontAwesomeIcon: FontAwesomeIcon) {
    this.selectedFontAwesomeIcon = fontAwesomeIcon;
    this.$emit('update-fontawesome-icon', this.selectedFontAwesomeIcon);
  }

  private BackToParent() {
    this.$emit('selectBack', false);
    this.$emit('update-fontawesome-icon', this.selectedFontAwesomeIcon);
  }

  private mounted() {
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (this.customCategoryFolder) {
      this.selectedFontAwesomeIcon.Id = this.customCategoryFolder.FontAwesomeIconId;
      this.selectedFontAwesomeIcon.IconName = this.customCategoryFolder.FontAwesomeIconName;
      if (this.selectedFontAwesomeIcon.Id === 0) {
        this.SetDefaultPinIcon();
      }
    } else {
      this.SetDefaultPinIcon();
    }
  }

  private SetDefaultPinIcon() {
    this.selectedFontAwesomeIcon.Id = 17;
    this.selectedFontAwesomeIcon.IconName = 'fa fa-info-circle';
  }
}
