
























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomCategoryMultimediaItem } from '@/entities/CustomCategory/CustomCategoryMultimediaItem';
import CustomCategoryMultimediaEditItemPane from '@/components/CustomCategory/CustomCategoryDetail/Multimedia/CustomCategoryMultimediaEditItemPane.vue';
import CustomCategoryMultimediaListAndEditItemView from '@/components/CustomCategory/CustomCategoryDetail/Multimedia/CustomCategoryMultimediaListAndEditItemView.vue';

@Component({
  components: {
    CustomCategoryMultimediaEditItemPane,
    CustomCategoryMultimediaListAndEditItemView,
  },
})
export default class MultimediaListView extends Vue {
  @Prop() private customCategoryMedias?: CustomCategoryMultimediaItem[];
  @Prop() private selectedCustomCategoryId?: number;
  private localMultimedia?: CustomCategoryMultimediaItem[] = [];
  private localMultimediaLoading?: boolean = false;
  private loaderBorderColor = '';
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private loadingImage: string = require('@/assets/Images/loading.png');
  private showFileUploadProcess = false;
  private localMultimediaItem: CustomCategoryMultimediaItem | null = null;
  private currentEditMultimediaItem: CustomCategoryMultimediaItem | null = null;

  private ClosePreviouslyOpenMultimediaForEdit() {
    this.$root.$emit('customCategory-multimedia-close-others-operation');
  }

  private AddMultimediaItem() {
    this.ClosePreviouslyOpenMultimediaForEdit();
    const newItem = CustomCategoryMultimediaItem.createEmpty();
    newItem.CustomCategoryId = this.selectedCustomCategoryId!;
    this.localMultimediaItem = newItem;
    const el = document.querySelector('#dvMultimediaListing');
    if (el) {
      this.scrollToBottom(el);
    }
  }
  private setCurrentlocalItem(multimedia: CustomCategoryMultimediaItem) {
    this.currentEditMultimediaItem = multimedia;
    this.currentEditMultimediaItem.CustomCategoryId = this.selectedCustomCategoryId!;
  }
  private clickOnImage(val: boolean) {
    this.showFileUploadProcess = val;

    if (this.currentEditMultimediaItem != null) {
      this.$emit('customCategory-multimedia-item-showImageComponent-operation', {
        showFileUploadProcess: val,
        selectedMultimediaitem: this.currentEditMultimediaItem,
      });
    } else {
      this.$emit('customCategory-multimedia-item-showImageComponent-operation', {
        showFileUploadProcess: val,
        selectedMultimediaitem: this.localMultimediaItem,
      });
    }
  }
  private CustomCategoryMultimediaItemCancelOperation() {
    this.localMultimediaItem = null;
    this.currentEditMultimediaItem = null;
  }

  private CustomCategoryMultimediaItemEditedOperation() {
    this.localMultimediaItem = null;
    this.currentEditMultimediaItem = null;
  }

  private mounted() {
    if (this.customCategoryMedias != null) {
      this.localMultimedia = this.customCategoryMedias;
    }
    this.$root.$on('customCategoryDetailLoading', (loading: boolean) => {
      this.localMultimediaLoading = loading;
    });
    this.$root.$on('customCategory-multimediaData', (multimedias: CustomCategoryMultimediaItem[]) => {
      // make default list for new customCategory item
      this.localMultimediaItem = null;
      this.localMultimedia = multimedias;
    });
    this.$root.$on('clearCustomCategoryMultimedia', () => {
      this.localMultimedia = [];
    });
    this.$root.$on(
      'customCategory-multimedia-item-imageupdated-operation',
      (multimedia: any) => {
        this.showFileUploadProcess = false;
        const selectedItem = multimedia.selectedMultimediaitem;
        if (selectedItem.Id === 0) {
          this.localMultimediaItem = selectedItem;
        } else {
          this.currentEditMultimediaItem = selectedItem;
        }
        const el = document.querySelector('#dvMultimediaListing');
        if (el) {
          this.scrollToBottom(el);
        }
      },
    );

    this.$root.$on('customCategory-multimedia-close-others-operation', () => {
      this.currentEditMultimediaItem = null;
      this.localMultimediaItem = null;
    });
  }
  private GetImageUrl(item: CustomCategoryMultimediaItem) {
    const multiMediaImageUrl = this.localMultimedia!.find(
      (x) => x.Id === item.Id,
    )!.ThumbUrl;
    if (multiMediaImageUrl !== null) {
      return multiMediaImageUrl;
    }
    return this.missingImage;
  }

  private scrollToBottom(el: Element) {
    setTimeout(() => {
      el.scrollTop = el.scrollHeight;
      el.scrollIntoView();
    }, 100);
  }
}
