import i18n from '@/i18n';
import { AxiosInstance } from 'axios';
export class CustomCategoryType {
  public static createEmpty(): CustomCategoryType {
    return new CustomCategoryType();
  }
  constructor(
    public Id: number = 0,
    public Name: string = '',
    public IconUrl: string = '',
    public IconPadding: string= '',
    public Description: string = '',
    public DisplayName: string = '',
  ) { }
}
export class CustomCategoryTypes extends CustomCategoryType {
  public arrCustomCategoryOptions: CustomCategoryType[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(rootObj: any, axios: AxiosInstance, $store: any, $t: any) {
    super();
    this.arrCustomCategoryOptions.push({Id: 0, Name: i18n.t('CustomCategory.Speaker').toString(), IconUrl: 'fa fa-microphone font-awesome-icon', IconPadding: 'padding:17px 25px', Description: 'Add a speaker. Link to participant profile and program item(s)',DisplayName:i18n.t('CustomCategory.Speaker').toString()});
    this.arrCustomCategoryOptions.push({Id: 1, Name: i18n.t('CustomCategory.Sponsor').toString(), IconUrl: 'fa fa-handshake-o font-awesome-icon',  IconPadding: 'padding:17px 12px', Description: 'Add a sponsor. Link to participant profile and program item(s)',DisplayName:i18n.t('CustomCategory.Sponsor').toString()});
    this.arrCustomCategoryOptions.push({Id: 2, Name: i18n.t('CustomCategory.Presentation').toString(), IconUrl: 'fa fa-file-pdf-o font-awesome-icon',  IconPadding: 'padding:17px', Description: 'Upload hand-outs, presentation decks, venue maps, food menus, etc',DisplayName:i18n.t('CustomCategory.PdfDocument').toString()});
    this.arrCustomCategoryOptions.push({Id: 3, Name: i18n.t('CustomCategory.WebPage').toString(), IconUrl: 'fa fa-window-maximize font-awesome-icon',  IconPadding: 'padding:17px', Description: 'Link to a webpage or other URL',DisplayName:i18n.t('CustomCategory.WebPage').toString()});
    this.arrCustomCategoryOptions.push({Id: 4, Name: i18n.t('CustomCategory.SocialMedia').toString(), IconUrl: 'fa fa-users font-awesome-icon',  IconPadding: 'padding:17px', Description: 'Add link to LinkedIn, Instagram, Twitter, Facebook, and more',DisplayName:i18n.t('CustomCategory.SocialMedia').toString()});
    this.arrCustomCategoryOptions.push({Id: 5, Name: i18n.t('CustomCategory.OtherInformation').toString(), IconUrl: 'fa fa-info-circle font-awesome-icon',  IconPadding: 'padding:17px', Description: 'Add any other text-based information',DisplayName:i18n.t('CustomCategory.OtherInformation').toString()});
  }
}
