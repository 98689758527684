import { Create } from './CustomCategoryFactoryCreator';
import { BaseCustomCategoryRenderer} from './BaseCustomCategoryRenderer';
import CustomCategoryTypeEnum from '@/enums/CustomCategoryTypeEnum';
import i18n from '@/i18n'; // its used for translation. i18n.t() same as $t

export class SpeakerRenderer implements Create {
  public GetValues(typeId: number): BaseCustomCategoryRenderer {
    const SpeakerRendererBaseClass: BaseCustomCategoryRenderer = new BaseCustomCategoryRenderer();
    SpeakerRendererBaseClass.CustomCategoryType = CustomCategoryTypeEnum[typeId].toString();
    SpeakerRendererBaseClass.CustomCategoryTypeId = typeId;
    SpeakerRendererBaseClass.ShowImage = true;
    SpeakerRendererBaseClass.ShowDescription = true;
    SpeakerRendererBaseClass.ShowLinkedParticipantProfile = true;
    SpeakerRendererBaseClass.ShowLinkedProgramItem = true;
    SpeakerRendererBaseClass.ShowMultimedia = true;
    SpeakerRendererBaseClass.ImageUploadText = i18n.t('AddImage').toString();
    SpeakerRendererBaseClass.DescriptionText = i18n.t('CustomCategory.AboutSpeaker').toString();
    SpeakerRendererBaseClass.LinkParticipantProfileText = i18n.t('CustomCategory.LinkSpeakerParticipantText').toString();
    SpeakerRendererBaseClass.TitleText = i18n.t('Name').toString();
    SpeakerRendererBaseClass.CustomCategoryHeading =  i18n.t('CustomCategory.Speaker').toString()+' '+ i18n.t('Information.Information').toString();
    SpeakerRendererBaseClass.LinkParticipantProfileNoProfileText = i18n.t('CustomCategory.LinkParticipantProfileNoProfileText', {type: SpeakerRendererBaseClass.CustomCategoryType}).toString();
    SpeakerRendererBaseClass.ShowVOD = true;
    return SpeakerRendererBaseClass;
  }
}
