






































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProgramItem } from '@/entities/Program/ProgramItem';
@Component
export default class CustomCategoryLinkedProgramListView extends Vue {
  @Prop() private linkedProgram?: ProgramItem[];
  @Prop() private selectedCustomCategoryId?: number;
  private localLinkedProgram?: ProgramItem[] = [];
  private localAllPrograms?: ProgramItem[] = [];
  private localLinkedProgramLoading?: boolean = false;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private loadingImage: string = require('@/assets/Images/loading.png');

  private mounted() {
    if (this.linkedProgram != null) {
      this.localLinkedProgram = [];
      this.localAllPrograms = [];
      this.linkedProgram.forEach((element) => {
        if (element.IsLinked) {
          this.localLinkedProgram!.push(element);
        }
        this.localAllPrograms!.push(element);
      });
    }
    this.$root.$on('customCategoryDetailLoading', (loading: boolean) => {
      this.localLinkedProgramLoading = loading;
    });
    this.$root.$on(
      'linkedProgramData',
      (program: ProgramItem[]) => {
        this.localLinkedProgram = [];
        this.localAllPrograms = [];
        program.forEach((element) => {
          if (element.IsLinked) {
            this.localLinkedProgram!.push(element);
          }
          this.localAllPrograms!.push(element);
        });
      },
    );
    this.$root.$on('clearPrograms', () => {
      this.localLinkedProgram = [];
      this.localAllPrograms = [];
    });
  }
  private GetImageUrl(item: ProgramItem) {
    const customCategoryImageUrl = this.localLinkedProgram!.find(
      (x) => x.Id === item.Id,
    )!.ImageUrl;
    if (customCategoryImageUrl !== null) {
      return customCategoryImageUrl;
    }
    return this.missingImage;
  }
  private clickOnAddProgram() {
    this.$emit('showAttachProgram', {
      showAddPrograms: true,
      programs: this.localAllPrograms,
    });
  }
}
