import { render, staticRenderFns } from "./CustomCategoryFolderListView.vue?vue&type=template&id=4bf341cf&scoped=true&"
import script from "./CustomCategoryFolderListView.vue?vue&type=script&lang=ts&"
export * from "./CustomCategoryFolderListView.vue?vue&type=script&lang=ts&"
import style0 from "./CustomCategoryFolderListView.vue?vue&type=style&index=0&id=4bf341cf&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bf341cf",
  null
  
)

export default component.exports