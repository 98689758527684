import { Create } from './CustomCategoryFactoryCreator';
import { BaseCustomCategoryRenderer} from './BaseCustomCategoryRenderer';
import CustomCategoryTypeEnum from '@/enums/CustomCategoryTypeEnum';
import i18n from '@/i18n'; // its used for translation. i18n.t() same as $t

export class OtherInformationRenderer implements Create {
  public GetValues(typeId: number): BaseCustomCategoryRenderer {
    const OtherInformationRendererBaseClass: BaseCustomCategoryRenderer = new BaseCustomCategoryRenderer();
    OtherInformationRendererBaseClass.CustomCategoryType = CustomCategoryTypeEnum[typeId].toString();
    OtherInformationRendererBaseClass.CustomCategoryTypeId = typeId;
    OtherInformationRendererBaseClass.ShowImage = true;
    OtherInformationRendererBaseClass.ShowAddress = true;
    OtherInformationRendererBaseClass.ShowDescription = true;
    OtherInformationRendererBaseClass.ShowLinkedProgramItem = true;
    OtherInformationRendererBaseClass.ShowMultimedia = true;
    OtherInformationRendererBaseClass.ImageUploadText = i18n.t('AddImage').toString();
    OtherInformationRendererBaseClass.DescriptionText = i18n.t('Description').toString();
    OtherInformationRendererBaseClass.TitleText = i18n.t('Title').toString();
    OtherInformationRendererBaseClass.CustomCategoryHeading =  i18n.t('Other').toString() +' '+ i18n.t('Information.Information').toString();
    OtherInformationRendererBaseClass.ShowVOD = true;
    return OtherInformationRendererBaseClass;
  }
}
